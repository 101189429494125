.loadingPageFull {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11rem;
  background: #ccc9c983;
  position: fixed;
  top: 0px;
  left: 0px;
}

.ripple {
    width: 5rem; /* control the size */
    background: #000; /* control the color here */
  }
  .ripple,
  .ripple::before,
  .ripple::after {
    content: "";
    display: grid;
    grid-area: 1/1;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #473f3f; /* and here, 3 is the transparency */
    animation: r 3s linear infinite var(--s, 0s);
  }
  .ripple::before {
    --s: 1s;
  }
  .ripple::after {
    --s: 2s;
  }
  
  @keyframes r {
    to {
      box-shadow: 0 0 0 6rem #0000;
    }
  }
  