.container{
    width: 1200px;
    height: 500px;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
}
.alignment-container {
    height: 82%;
    /* border: 1px solid #000; */
    display: flex;
}
.control-panel{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 18%;
    width: 100%;
    margin: 0px auto;
    margin-top: 20px;
}
.control-panel img{
    width: 30px;
    cursor: pointer;
}
/* .container {
    width: 100%;
    height: 500px;
    border: 5px solid #000;
    display: flex;
} */
.alignmentBox1,
.alignmentBox2 {
    height: 100%;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
}
.alignmentBox1{
    width: 100%;
}
.alignmentBox2{
    width: 0%;
}
.source-box,
.target-box,
.source-box1,
.target-box1 {
    width: 100%;
    height: 50%;
    overflow: auto;
}
.source-box{
    border-bottom: 1px solid #000;
}
.target-box{
    display: flex ;
    flex-direction: column;
    overflow: hidden;
}
.target-box .target-suggestions{
    overflow: auto;
}
.target-box .target-textbox{
    height: 30%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.source-box1,
.target-box1{
    border: none;
}
.token {
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    background-color: #c66b3d ;
    border-radius: 5px;
    margin: 0.5rem;
    padding: 0.05rem 0.25rem;
    border: 2px solid transparent;
    cursor: pointer;
    z-index: 500;
    font-weight: 500;
    color: #FFF;
}
.token:hover{
    box-shadow: 0 0 1pt 3pt darkblue;
}
.active-token{
    background-color: #2649fc;
    color: #fff;
}
.set-token{
    background-color: #1e847f;
    color: #FFF;
}
.not-allowed-token{
    cursor: not-allowed;
}
.not-allowed-token:hover{
    box-shadow: none !important;
}
.final-submission-button{
    display: flex;
    align-items: center;
}