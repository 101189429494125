.container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal{
    width: 70%;
    height: 90%;
    background-color: #FFF;
    z-index: 9999;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
    padding: 5px;
}
.releaseNoteContainer{
    margin: 5px auto;
    width: 98%;
    height: 90%;
    overflow-y: auto;
    text-align: left;
    padding: 5px;
}
.releaseNoteContainer dt{
    font-weight: 600;
}